import React, { useEffect, useState } from "react"
import { FarmacieInVendita_Sb } from './FarmacieInVendita_Sb';
import { useParams } from "react-router-dom";
import { CookiesProvider, useCookies } from "react-cookie";

export const RicercaFarmacie_lista = () => {

  const [cookies, setScrollCookie, removeCookie] = useCookies(['scroll']);

  const { regione } = useParams();
  var pagina = "";
  var titolo = "";
  var parametro = "";

  if (titolo == "") {
    pagina = "/FarmaciaInVendita/";
    titolo = "Inserzioni farmacie in vendita in " + regione;
    parametro = regione;
  }

  if (regione == "ricerca") {
    pagina = "/RicercaFarmacia/";
    titolo = "Inserzioni di ricerca farmacie in vendita";
    parametro = "2";
  }
  if (regione == "vendita") {
    pagina = "/FarmaciaInVendita/";
    titolo = "Inserzioni di farmacie in vendita";
    parametro = "1";
  }

  let api_url = "https://org.farmacontatto.it/PhsCrm/ricerche/esegui/get_ricerche_web.php?p=" + parametro

  const [DatiJson, setFarma] = useState([])
  let [isLoaded, setIsLoaded] = useState(false);
  let [err, setErr] = useState(null);

  // retrive scroll inizio
  const handleScroll = () => {
    const scrollPosition = window.scrollY; // => scroll position
    setScrollCookie('scroll', scrollPosition, { maxAge: 3600 })
    console.log(scrollPosition);
  };
  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // retrive scroll fine


  useEffect(() => {
    const getFarma = () => {
      fetch(api_url)
        .then(response => {
          if (response.status >= 400) {
            throw new Error("Si è verificato un errore")
          }
          return response.json()
        })
        .then(DatiJson => {
          setFarma(DatiJson)
          setIsLoaded(true)
        },
          err => {
            setErr(err)
            setIsLoaded(true)
          })
    };
    getFarma()
  }, [])


  if (err) {
    return <div> {err.message} </div>
  } else if (!isLoaded) {
    return <div> Loading... </div>
  } else {
    return (
      <>
        <div className='my-div'>
          <div className="row g-2">
            <div className="col-md-9">
              <article className="blog-post p-4"
                style={{ backgroundImage: "linear-gradient(180deg, #F7F9FC, #FFFFFF)" }}>
                <h1 className="blog-post-meta">
                  {titolo}
                </h1>
                <div className="row flex-md-row pt-3">
                  {DatiJson.length > 0 ?
                    <div className="col-md-12">
                      {DatiJson.map(farma => (
                        <div className="row mb-2" key={farma.url_pagina}>

                          <div className="col-md-12">
                            <div className="card flex-md-row mb-4 box-shadow h-md-250 ">
                              <div className="d-none d-lg-block">
                                <a href={pagina + farma.url_pagina}><img className='rounded-circle m-3' src={"../../assets/img/Farmacie-in-vendita-" + farma.immagine + '.jpg'} alt={farma.title_immagine} title={farma.title_immagine} width="100" height="100" loading="lazy" /></a>
                              </div>
                              <div className="card-body d-flex flex-column align-items-start">

                                <div className="container">
                                  <div className="row justify-content-between">
                                    <div className={"col-xl-4 col-md-6 col-sm-8 my-trattativa-" + farma.colore_stato_trattative}>{farma.stato_trattative}
                                    </div>
                                    <div className={"col-xl-2 col-md-3 col-sm-4 my-text-dark"}>Rif: {farma.rif_ricerca}
                                    </div>
                                  </div>
                                </div>

                                <a href={pagina + farma.url_pagina}>
                                  <h2 className="my-text-dark mt-2" href="#">{farma.titolo_inserzione}
                                  </h2>
                                </a>
                                <p className="my-card-text text-start mx-4 ">{farma.testo_inserzione}</p>
                                <button type="button" className="btn btn-warning btn-md">
                                  <a className='btn-href my-text-dark' href={pagina + farma.url_pagina}>Scopri di più</a>
                                </button>

                              </div>
                            </div>
                          </div>

                        </div>
                      ))}
                    </div>
                    : <div> Farmacie non trovate! </div>}
                  <div className="col-12" id='Comprare una farmacia'>
                  </div>
                </div>
              </article>
            </div>
            < FarmacieInVendita_Sb />
          </div >
        </div >
      </>
    );
  }
}

export default RicercaFarmacie_lista;