import { Inserzioni_Sb } from './Inserzioni_Sb';
import envelope_circle_check_solid from './icone/envelope-circle-check-solid.svg';
import InserzioneBtnModal from './InserzioneBtnModal';
import { Home_Rg4 } from './Home_Rg4';

export const Inserzione_moduli = () => {
  return (
    <>
      <div className='my-div'>
        <div className="row g-2">
          <div className="col-md-9">
            <article className="blog-post p-4"
              style={{ backgroundImage: "linear-gradient(180deg, #F7F9FC, #FFFFFF)" }}>

              <h1 className="blog-post-meta">
                Inserisci qui il tuo annuncio<br></br>sui nostri portali specializzati
              </h1>

                <div className="row justify-content-md-center">
                  <div className="col-6 my-trattativa-verde">
                    Le inserzioni sono gratuite
                  </div>
                </div>
     
              <div className="row flex-md-row pt-3">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-12 p-4"
                      style={{ backgroundImage: "linear-gradient(180deg, #F7F9FC, #FFFFFF)" }}>
                      <div className="row g-0 overflow-hidden flex-md-row">
                        <div className="col p-4 d-flex flex-column position-static" id='Noi compriamo la tua farmacia'>

                          <h4 style={{ color: '#f57e20', fontWeight: 'bold' }}>INSERZIONI DI RICERCA</h4>{' '}
                          <h2 className="d-inline-block mb-2 text-primary-emphasis">RICERCA FARMACIE IN VENDITA</h2>
                          <h2 className="mb-0 text-primary-emphasis mb-2">compila in modulo e in breve tempo sarai richiamato.</h2>
                          <div className="my-div mb-4">Facci solo capire cosa vorresti fare, ti spiegheremo<br></br> come funziona il servizio e concorderemo il testo dell'inserzione.
                          </div>
                          <p className="card-text mb-auto">
                            <InserzioneBtnModal riff=' di ricerca' />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 p-4"
                      style={{ backgroundImage: "linear-gradient(180deg, #F7F9FC, #FFFFFF)" }}>
                      <div className="row g-0 overflow-hidden flex-md-row">
                        <div className="col p-4 d-flex flex-column position-static" id='Noi compriamo la tua farmacia'>

                          <h4 style={{ color: '#f57e20', fontWeight: 'bold' }}>INSERZIONI DI VENDITA</h4>{' '}
                          <h2 className="d-inline-block mb-2 text-primary-emphasis">VENDITA FARMACIE</h2>
                          <h2 className="mb-0 text-primary-emphasis mb-2">compila in modulo e in breve tempo sarai richiamato.</h2>
                          <div className="my-div mb-4">Facci solo capire cosa vorresti fare, ti spiegheremo<br></br> come funziona il servizio.
                            <br></br>Se vuoi solo una valutazione della tua farmacia,<br></br>contattaci per informazioni nella massima riservatezza</div>
                          <p className="card-text mb-auto">
                            <InserzioneBtnModal riff=' di vendita' />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
< Home_Rg4 />
            </article>
          </div>
          < Inserzioni_Sb />
        </div>
      </div>
    </>
  )
}

export default Inserzione_moduli;