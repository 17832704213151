

import './App.css';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import { Home } from './components/Home';

import { InserzioniFarmacie } from './components/InserzioniFarmacie';
import { RicercaFarmacie } from './components/RicercaFarmacie';
import { FarmacieInVendita } from './components/FarmacieInVendita';
import { FarmaciaInVendita } from './components/FarmaciaInVendita';
import { RicercaFarmacia } from './components/RicercaFarmacia';

import { Inserzione } from './components/Inserzione';
import { Chisiamo } from './components/Chisiamo';
import { Cookies } from './components/Cookies';
import { Condizioni } from './components/Condizioni';
import { Privacy } from './components/Privacy';
import { Footer } from './components/footer';
import { NotFound } from './components/NotFound';

import ScrollToTop from "./components/ScrollToTop";

function App() {

  return (
    <BrowserRouter>
      <div className="App">
        <div>
          <Routes>
            <Route path='*' element={<NotFound />} />
            <Route path='/' element={<Home />} />

            <Route path='InserzioniFarmacie/:regione' element={<InserzioniFarmacie />} />

            <Route path='RicercaFarmacie/:regione' element={<RicercaFarmacie />} />
            <Route path='/RicercaFarmacia/:url_pagina' element={<RicercaFarmacia />} />
            
            <Route path='FarmacieInVendita/:regione' element={<FarmacieInVendita />} />
            <Route path='/FarmaciaInVendita/:url_pagina' element={<FarmaciaInVendita />} />
            
            <Route path='inserzione' element={<Inserzione />} />
            <Route path='Chisiamo' element={<Chisiamo />} />
   
            <Route path='Cookies' element={<Cookies />} />
            <Route path='Condizioni' element={<Condizioni />} />
            <Route path='Privacy' element={<Privacy />} />

          </Routes>
        </div>
{/*
  */}
        <ScrollToTop />
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
