import React from "react";
import { Navbar2 } from './navbar2';

export const Cookies = () => {

    return (
        <>
            <Navbar2 />
            <div style={{ marginTop: '100px' }}>

                <div className="container-fluid pt-5 g-0" style={{
                    width: "80%",
                    marginTopTop: 80,
                    paddingLeft: "1em",
                    paddingRight: "1em",
                    paddingTop: 60,
                    paddingBottom: 30,
                    border: "3px solid lightGray",
                }}>
                    <h1>Informazioni generalli sull'uso dei cookies</h1>
                </div>

                <div className="container-fluid pt-3 g-0" style={{
                    width: "80%",
                    paddingLeft: "1em",
                    paddingRight: "1em",
                    paddingTop: 30,
                    paddingBottom: 30,
                    border: "3px solid lightGray",
                    textAlign: "justify",
                }}>

                    <h2><strong>Cookie Policy di pharmabroker.it</strong></h2>
                    Si informa che su pharmabroker.it (“Sito”) utilizziamo cookie allo scopo di facilitare e migliorare la navigazione del sito web e migliorare la qualità della stessa. Il presente documento fornisce all’utente (“interessato al trattamento”) informazioni dettagliate sull’uso dei cookie (e tecnologie similari), su come sono utilizzati dal Sito e su come gestirli.
                    <h3><strong>Cosa sono i cookie</strong></h3>
                    I cookie sono stringhe di testo di piccole dimensioni che i siti visitati dall’utente inviano al suo terminale (solitamente al browser), dove vengono memorizzati per essere poi ritrasmessi agli stessi siti alla successiva visita del medesimo utente. Nel corso della navigazione, l’utente può ricevere anche cookie che vengono inviati da siti o da web server diversi, così detti Cookie “terze parti”, sui quali possono risiedere alcuni elementi (quali, ad esempio, immagini, mappe, suoni, specifici link a pagine di altri domini) presenti sul Sito che lo stesso sta visitando.

                    I cookie, solitamente presenti nei browser degli utenti in numero molto elevato e a volte anche con caratteristiche di ampia persistenza temporale, sono usati per differenti finalità: esecuzione di autenticazioni informatiche, monitoraggio di sessioni, memorizzazione di informazioni su specifiche configurazioni riguardanti gli utenti che accedono al server, ecc.
                    <h4>I cookies possono essere suddivisi in:</h4>
                    <ul>
                        <li>cookie di navigazione o di sessione, che garantiscono la normale navigazione e fruizione del sito web (permettendo, ad esempio, di autenticarsi per accedere ad aree riservate);</li>
                        <li>cookie analytics, assimilati ai cookie tecnici laddove utilizzati direttamente dal gestore del sito per raccogliere informazioni, in forma aggregata, sul numero degli utenti e su come questi visitano il sito stesso;</li>
                        <li>cookie di funzionalità, che permettono all’utente la navigazione in funzione di una serie di criteri selezionati (ad esempio, la lingua) al fine di migliorare il servizio reso allo stesso. I cookies (esclusi quelli tecnici) utilizzati attualmente sul Sito sono cookies per gestire la sessione utente.</li>
                    </ul>
                    Quelli di terze parti sono di Google analytics.

                    Quando l’installazione di cookies avviene sulla base del consenso, tale consenso può essere revocato liberamente in ogni momento seguendo le istruzioni contenute in questo documento.
                    <h3><strong>Tipi di cookie utilizzati</strong></h3>
                    Vengono di seguito indicate le tipologie di cookie utilizzate dal Sito:
                    <h3><u>Cookie Tecnici</u></h3>
                    Questa tipologia di cookie permette il corretto funzionamento di alcune sezioni del Sito. Sono di due categorie, persistenti e di sessione:

                    - persistenti: una volta chiuso il browser non vengono distrutti ma rimangono fino ad una data di scadenza preimpostata;
                    - di sessione: vengono distrutti ogni volta che il browser viene chiuso.

                    Questi cookie, inviati sempre dal nostro dominio, sono necessari a visualizzare correttamente il Sito e in relazione ai servizi tecnici offerti, verranno quindi sempre utilizzati e inviati, a meno che l’Utente non modifichi le impostazioni nel proprio browser (alterando la visualizzazione delle pagine del Sito).

                    <u>Attività strettamente necessarie al funzionamento</u>

                    Questo Sito utilizza cookie per salvare la sessione dell’Utente e per svolgere altre attività strettamente necessarie al funzionamento dello stesso.

                    <u>Attività di salvataggio delle preferenze, ottimizzazione e statistica</u>

                    Questo Sito utilizza cookie per salvare le preferenze di navigazione ed ottimizzare l’esperienza di navigazione dell’Utente. Fra questi cookie rientrano, ad esempio, per la gestione di statistiche da parte del Titolare del sito.
                    <h3><strong>Tecnologie e cookie di terze parti</strong></h3>
                    <h4><u>Cookie analitici</u></h4>
                    I cookie di questa categoria vengono utilizzati per collezionare informazioni sull’uso del Sito. Ci riserviamo la facoltà di usare queste informazioni per sviluppare analisi statistiche anonime e migliorare l’utilizzo del Sito. Questa tipologia di cookie raccoglie dati in forma anonima sull’attività dell’utenza e su come è arrivata sul Sito. I cookie analitici sono inviati dal Sito stesso o da domini di terze parti. Possono rientrare nella categoria dei cookie tecnici laddove utilizzati direttamente dal gestore del sito per raccogliere informazioni in forma aggregata e in modo anonimo.

                    Un servizio di analisi del traffico web viene fornito da Google, Inc. (“Google”). Si tratta di cookie di terze parti raccolti e gestiti in modo anonimo per monitorare e migliorare le prestazioni del sito ospite (performance cookie). Google Analytics, in particolare, utilizza i “cookie” da questo Sito per raccogliere e analizzare in forma anonima le informazioni sui comportamenti di utilizzo dei siti.

                    Tali informazioni vengono raccolte da Google Analytics, che le elabora allo scopo di redigere report per gli operatori riguardanti le attività sui siti web stessi. Per ulteriori informazioni, si rinvia al link di seguito indicato: <a href="http://www.google.com/policies/privacy/">http://www.google.com/policies/privacy/</a>.

                    L’utente può disabilitare in modo selettivo l’azione di Google Analytics installando sul proprio browser la componente di opt-out fornito da Google. Per disabilitare l’azione di Google Analytics, si rinvia al link di seguito indicato: <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.

                    Questo Sito non utilizza (e non consente a terzi di utilizzare) lo strumento di analisi di Google per monitorare o per raccogliere informazioni personali di identificazione. In ogni caso, laddove utilizzato, avremo cura di adottare la funzione di anonimizzazione degli IP dell’utente in Analytics, tramite la cd. «Mascheratura dell’indirizzo IP» (in questo modo Google non assocerà l’indirizzo IP a nessun altro dato posseduto, né cercherà di collegare un indirizzo IP con l’identità di un utente).
                    <h4><u>Cookie di terze parti</u></h4>
                    Il nostro Sito utilizza anche cookie di terze parti per fruire delle interazioni con i social network (social plug-in). La presenza di questi plugin comporta la trasmissione di cookie da e verso tutti i siti gestiti da terze parti. La gestione delle informazioni raccolte da “terze parti” è disciplinata dalle relative informative cui si prega di fare riferimento. Per garantire una maggiore trasparenza e comodità, si riportano qui di seguito gli indirizzi web delle diverse informative e delle modalità per la gestione dei cookie

                    Questi plug-in non impostano un cookie, ma se esso è già presente sul computer del visitatore sono in grado di leggerlo ed utilizzarlo secondo le sue impostazioni. La raccolta e l’uso delle informazioni da parte di tali terzi sono regolati dalle rispettive informative privacy alle quali si rinvia.

                    Si ricorda, inoltre, che se si fa un “clic” su questi link, tali siti web potrebbero inserire dei cookie una volta che ci si connette ai loro servizi.

                    I cookie social non sono necessari alla navigazione e, come stabilito dall’Autorità Garante per la protezione dei dati italiana, se rientrano tra quelli di profilazione, deve essere fornito il consenso per l’installazione di tali cookie sul dispositivo dell’utente. Nel caso specifico, tuttavia, si tratta di semplici link a siti terze parti che non installano cookie di profilazione.

                    In particolare, il presente Sito utilizza i plug-in di Facebook e Linkedin. I cookie di queste terze parti non sono sotto il controllo del Titolare e, pertanto, per ulteriori informazioni su come la terza parte utilizzi i cookie, si consiglia di visitare il relativo sito web di cui qui di seguito si riportano gli indirizzi web ove è possibile anche reperire le specifiche informative e le modalità di gestione dei cookies:

                    - Facebook: http://www.facebook.com/about/privacy/

                    - Linkedin: https://www.linkedin.com/legal/cookie-policy

                    <u>Interazione con social network e piattaforme esterne</u>

                    Questo tipo di servizi permette di effettuare interazioni con i social network, o con altre piattaforme esterne, direttamente dalle pagine di questo Sito. Le interazioni e le informazioni acquisite da questo Sito sono in ogni caso soggette alle impostazioni privacy dell’Utente relative ad ogni social network. Questo tipo di servizio potrebbe comunque raccogliere dati sul traffico per le pagine dove il servizio è installato, anche quando gli Utenti non lo utilizzano. Si raccomanda di disconnettersi dai rispettivi servizi per assicurarsi che i dati elaborati su questo Sito Web non vengano ricollegati al profilo dell’Utente.

                    <u>Pulsante Mi Piace e widget sociali di Facebook (Facebook, Inc.)</u>

                    Il pulsante “Mi Piace” e i widget sociali di Facebook sono servizi di interazione con il social network Facebook, forniti da Facebook, Inc.

                    Dati Personali raccolti: Cookie e Dati di utilizzo. Luogo del trattamento: Stati Uniti - <a href="https://www.facebook.com/privacy/explanation">Privacy Policy</a>. Soggetto aderente al Privacy Shield.

                    <u>Pulsante e widget sociali di Linkedin (LinkedIn Corporation)</u>

                    Il pulsante e i widget sociali di LinkedIn sono servizi di interazione con il social network Linkedin, forniti da LinkedIn Corporation. Dati Personali raccolti: Cookie e Dati di utilizzo. Luogo del trattamento: Stati Uniti - <a href="https://www.linkedin.com/legal/privacy-policy">Privacy Policy</a>
                    <h3><strong>Gestione dei cookie: consenso al relativo utilizzo</strong></h3>
                    La cancellazione dei cookies non preclude l’utilizzo del sito ma può comportare delle variazioni nella navigazione.

                    Gli utenti/visitatori possono impostare il browser del computer in modo tale che accetti/rifiuti tutti i cookies o visualizzi un avviso ogni qual volta viene proposto un cookie, per poter valutare se accettarlo o meno.

                    Vi sono a tale scopo dei componenti (plugin) per i più diffusi browser che consentono:

                    - la gestione (visualizzazione, cancellazione, blocco) dei cookie;
                    - la disabilitazione di pagine javascript di terze parti;
                    - la visualizzazione delle tecnologie impiegate dal sito;
                    - la visualizzazione ed il blocco (selettivo) dei diversi meccanismi di tracciamento.

                    Per default quasi tutti i browser web sono impostati per accettare automaticamente i cookie. Puoi comunque modificare la configurazione predefinita, che con browser (come, ad es. Internet Explorer) è quella media, e disabilitare i cookie (cioè bloccarli in via definitiva), impostando, nell’apposita scheda (“Privacy”), il livello di protezione più elevato (Altissimo), ma ti informiamo che la disabilitazione può compromette l’utilizzo di funzioni del sito. In ogni caso, hai anche la possibilità di cancellare (o eliminare) i cookie dal tuo personal computer, utilizzando l’apposita funzione presente nel tuo browser. La cancellazione dei cookie non preclude l’utilizzo del sito, ma comporta la ripetizione della procedura di autenticazione, ovvero il reinserimento delle tue credenziali d’accesso nell’area riservata. Se vuoi decidere di volta in volta se accettare o meno i cookie, puoi anche configurare il tuo browser affinché generi un avviso ogni volta che viene salvato un cookie.
                    <h4><strong>Durata</strong></h4>
                    I Cookies utilizzati sono cookies di sessione ovvero cookie temporanei che aiutano la navigazione dell’utente all’interno del sito e ricordano le scelte effettuate durante la sessione. Questi cookies si disattivano non appena si lascia il sito oppure dopo un arco di tempo di inutilizzo. Non vengono utilizzati cookies pubblicitari.
                    <h4><strong>Come disabilitare i cookie mediante configurazione del browser </strong></h4>
                    La maggior parte dei browser (Internet Explorer, Firefox, Chrome etc.) sono configurati per accettare i cookie. Tuttavia, la maggior parte dei browser permette di controllare e anche disabilitare i cookie attraverso le impostazioni del browser (ad esempio, in Firefox, attraverso il menu Strumenti-&gt;Opzioni-&gt;Privacy, è possibile accedere a un pannello di controllo dove è possibile definire se accettare o meno i diversi tipi di cookie e procedere alla loro rimozione). Si ricorda però che disabilitare i cookie di navigazione o quelli funzionali può causare il malfunzionamento del sito e/o limitare il servizio offerto.

                    La disabilitazione dei cookie di “terze parti” non pregiudica in alcun modo la navigabilità.

                    <u>Google Chrome</u>
                    <ol>
                        <li>Eseguire il Browser Chrome</li>
                        <li>Fare click sul menù presente nella barra degli strumenti del browser a fianco della finestra</li>
                    </ol>
                    di inserimento url per la navigazione
                    <ol start="3">
                        <li>Selezionare Impostazioni</li>
                        <li>Fare clic su Mostra Impostazioni Avanzate</li>
                        <li>Nella sezione “Privacy” fare clic su bottone “Impostazioni contenuti“</li>
                        <li>Nella sezione “Cookie” è possibile modificare le seguenti impostazioni relative ai cookie:</li>
                    </ol>
                    - Consentire il salvataggio dei dati in locale
                    - Modificare i dati locali solo fino alla chiusura del browser
                    - Impedire ai siti di impostare i cookie
                    - Bloccare i cookie di terze parti e i dati dei siti
                    - Gestire le eccezioni per alcuni siti internet
                    - Eliminazione di uno o tutti i cookie

                    Per maggiori informazioni visita la pagina dedicata https://support.google.com/chrome/answer/95647?hl=it.

                    <u>Mozilla Firefox</u>
                    <ol>
                        <li>Eseguire il Browser Mozilla Firefox</li>
                        <li>Fare click sul menù presente nella barra degli strumenti del browser a fianco della finestra di inserimento url per la navigazione</li>
                        <li>Selezionare Opzioni</li>
                        <li>Seleziona il pannello Privacy</li>
                        <li>Fare clic su Mostra Impostazioni Avanzate</li>
                        <li>Nella sezione “Privacy” fare clic su bottone “Impostazioni contenuti“</li>
                        <li>Nella sezione “Tracciamento” è possibile modificare le seguenti impostazioni relative ai</li>
                    </ol>
                    cookie:

                    - Richiedi ai siti di non effettuare alcun tracciamento
                    - Comunica ai siti la disponibilità ad essere tracciato
                    - Non comunicare alcuna preferenza relativa al tracciamento dei dati personali
                    <ol start="8">
                        <li>Dalla sezione “Cronologia” è possibile:</li>
                    </ol>
                    - Abilitando “Utilizza impostazioni personalizzate” selezionare di accettare i cookie di terze parti (sempre, dai siti più visitato o mai) e di conservarli per un periodo determinato (fino alla loro scadenza, alla chiusura di Firefox o di chiedere ogni volta)

                    - Rimuovere i singoli cookie immagazzinati

                    Per maggiori informazioni visita la pagina dedicata https://support.mozilla.org/it/kb/Gestione%20dei%20cookie.

                    <u>Internet Explorer</u>
                    <ol>
                        <li>Eseguire il Browser Internet Explorer</li>
                        <li>Fare click sul pulsante Strumenti e scegliere Opzioni Internet</li>
                        <li>Fare click sulla scheda Privacy e nella sezione Impostazioni modificare il dispositivo di</li>
                    </ol>
                    scorrimento in funzione dell’azione desiderata per i cookie:

                    - Bloccare tutti i cookie
                    - Consentire tutti i cookie
                    - Selezione dei siti da cui ottenere cookie: spostare il cursore in una posizione intermedia in modo da non bloccare o consentire tutti i cookie, premere quindi su Siti, nella casella Indirizzo Sito Web inserire un Sito internet e quindi premere su Blocca o Consenti

                    Per maggiori informazioni visita la pagina dedicata http://windows.microsoft.com/it-it/windows7/how-to-manage-cookies-in-internet-explorer-9.

                    <u>Safari OS X</u>
                    <ol>
                        <li>Eseguire il Browser Safari</li>
                        <li>Fare click su Safari, selezionare Preferenze e premere su Privacy</li>
                        <li>Nella sezione Blocca Cookie specificare come Safari deve accettare i cookie dai siti</li>
                        <li>Per visionare quali siti hanno immagazzinato i cookie cliccare su Dettagli</li>
                    </ol>
                    Per maggiori informazioni visita la pagina dedicata.

                    <u>Safari iOS</u>
                    <ol>
                        <li>Eseguire il Browser Safari iOS</li>
                        <li>Tocca su Impostazioni e poi Safari</li>
                        <li>Tocca su Blocca Cookie e scegli tra le varie opzioni: “Mai”, “Di terze parti e inserzionisti” o “Sempre”</li>
                        <li>Per cancellare tutti i cookie immagazzinati da Safari, tocca su Impostazioni, poi su Safari e</li>
                    </ol>
                    infine su Cancella Cookie e dati

                    Per maggiori informazioni visita la pagina dedicata http://support.apple.com/kb/HT1677?viewlocale=it_IT.

                    <u>Opera</u>
                    <ol>
                        <li>Eseguire il Browser Opera</li>
                        <li>Fare click sul Preferenze poi su Avanzate e infine su Cookie</li>
                        <li>Selezionare una delle seguenti opzioni:</li>
                    </ol>
                    - Accetta tutti i cookie
                    - Accetta i cookie solo dal Sito che si visita: i cookie di terze parti e che vengono inviati da un dominio diverso da quello che si sta visitando verranno rifiutati
                    - Non accettare mai i cookie: tutti i cookie non verranno mai salvati.

                    Per maggiori informazioni visita la pagina dedicata http://help.opera.com/Windows/10.00/it/cookies.html.

                    Per avere informazioni generali sui cookie che possono essere archiviati sul proprio terminale e disattivarli singolarmente qualora vi sia una loro presenza si rinvia ai link:

                    http://www.aboutcookies.org/ o http://www.youronlinechoices.com/it/le-tue-scelte.
                    <h3><strong>Diritti dell’interessato </strong></h3>
                    Per ricevere assistenza o spiegazioni su come esercitare il consenso (se dovuto) o il diniego selettivo o su come cancellare i cookie dal proprio browser ovvero per esercitare qualunque diritti previsti dalla normativa italiana ed europea in materia di trattamento dei dati personali (artt. 15-22 GDPR n. 679/2016), l’Utente può rivolgersi all’ e-mail info@farmacontatto.it; a tale indirizzo è possibile effettuare reclami/segnalazioni circa il funzionamento dei cookies.

                    Si ricorda inoltre che l’Utente ha sempre il diritto di proporre un reclamo all’Autorità Garante per la protezione dei dati personali per l’esercizio dei suoi diritti o per qualsiasi altra questione relativa al trattamento dei suoi dati personali.
                    <h3><strong>Titolare del trattamento</strong></h3>
                    Il titolare del trattamento dei dati raccolti a seguito della consultazione di questo sito, per i cookie di prima parte, è la società <span className="s1">Farma Contatto s.a.s. di Achille Bonzini &amp; C. con sede legale a Milano in Via Giambellino, 119, Email: achille.bonzini@farmacontatto.it - PEC : farmacontatto.pec.it - P. IVA 09550310966 REA CCIAA Milano 2098123.</span>
                    <h3><strong>Aggiornamento</strong></h3>
                    La nostra Informativa sulla privacy può cambiare di tanto in tanto e qualsiasi modifica sarà comunicata all’utente tramite un’email o un avviso sul nostro sito web; per tale motivo si invita l’Utente a consultarla con regolarità.

                    Per ottenere tutte le altre informazioni sul trattamento dei dati degli utenti si prega di consultare anche la privacy policy generale del sito web.

                    Ultimo aggiornamento Dicembre 2021


                </div>

            </div>
            <br></br>
        </>
    )
}