
import Agenzia_Farmacie from './Immagini/Agenzia-Farmacie.png';
import ChkOk from './Immagini/check.png';

export const Chisiamo_Rg3 = () => {
  return (
    <>
      <div className="row">
        <div className="container p-4">

          <div className="row pb-0 p-5 align-items-center"
            style={{ backgroundImage: "linear-gradient(180deg, #F7F9FC, #FFFFFF)" }}
          >
            <div className="row flex-sm-column-reverse flex-md-row">
              <div className="col-md-6 order-sm-2 align-self-center" id='Vendere la tua farmacia'>
                <img src={Agenzia_Farmacie} className="d-block mx-lg-auto img-fluid" alt="Pharmabroker - Inserzioni farmacie in vendita" width="650" loading="lazy" />
              </div>
              <div className="col-md-6 order-sm-1">
                <div className="mb-2 text-primary-emphasis text-start pt-4">
                  <h4 style={{ color: '#f57e20', fontWeight: 'bold' }}>CHI SIAMO E COME LA PENSIAMO</h4>{' '}
                  Siamo intermediari specializzati in farmacie e collaboriamo con altre agenzie del settore</div>
                <div style={{ fontSize: '1.1em' }}>
                  <p className="mb-1 text-secondary-emphasis mb-3 text-start">
                    Sappiamo bene che quello delle farmacie è un mercato esclusivo e particolare.
                  </p>
                  <p className="mb-1 text-secondary-emphasis mb-3 text-start">
                    Siamo contenti di collaborare con agenzie e seri  intermediari specializzati in farmacie e nella loro compravendita.
                  </p>
                  <p className="mb-1 text-secondary-emphasis mb-3 text-start">
                    Su Pharmabroker.it potrai inserire il tuo annuncio e troverai le inserzioni di altri privati farmacisti e delle agenzie specializzate che collaborano con noi.<br></br>Le inserzioni potranno essere visibili anche sul sito "Pharmascout.it".
                  </p>
                  <p className="mb-1 text-secondary-emphasis mb-3 text-start">
                  Il servizio è fornito con l'intento di fornire le maggiori possibilità di successo a chi sta cercando una farmacia da comprare o vuole mettere una farmacia in vendita.
                  </p>
                  <p className="mb-1 text-secondary-emphasis mb-3 text-start">
                    Le inserzioni sono gratuite, contattaci mediante gli appositi bottoni e compila il modulo; ti richiameremo presto per spiegare i dettagi del servizio e concordare il testo dell'annuncio e ti aiuteremo a realizzare i tuoi desideri. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Chisiamo_Rg3;





