
import logo from './images/logo.png';

import {
    Link
} from "react-router-dom";

import Container from 'react-bootstrap/Container';
import { Navbar } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { Link as Links } from 'react-scroll';
import NavDropdown from 'react-bootstrap/NavDropdown';

import ContattiBtnNavbar from './ContattiBtnNavbar';

export const Navbar1 = () => {

    return (
        <>
            <Navbar bg="light" expand="lg" fixed='top'>
                <Container>

                    <ContattiBtnNavbar />

                    <img src={logo} alt="Pharmabroker - inserzioni ricerca farmacie in vendita" width="60" height="60" style={{ marginRight: 15 }} className="d-inline-block align-text-top"></img>
                    <Navbar.Brand href="/" title="Pharmabroker - inserzioni ricerca farmacie in vendita"> Pharmabroker</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="navbar-brand" href="#">

                            <Nav.Link as={Link} to="/">Home</Nav.Link>
                            <Nav.Link as={Link} to="/inserzione">Nuova inserzione</Nav.Link>
                            <Nav.Link as={Link} to="/chisiamo">Chi siamo</Nav.Link>
                            <NavDropdown title="Privacy & Cookie" id="basic-nav-dropdown">
                                <NavDropdown.Item href="/Condizioni">Condizioni d’uso e Termini del servizio</NavDropdown.Item>
                                <NavDropdown.Item href="/Privacy">Trattamento dei dati personali (Privacy)</NavDropdown.Item>
                                <NavDropdown.Item href="/Cookies">Informazioni Generali sull’Uso dei Cookie</NavDropdown.Item>
                            </NavDropdown>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default Navbar1;